import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="about" />
	  <div className="blog-post">

	  
      <h2>About</h2>
      <p>This is a blog of my various musings. I will be developing AI systems for machine learning and probably document them here, and that's probably the only useful thing here.</p>
	  <p>
		  There's also a bunch of bad writing from my past days, and there might be more to come! oh boy!
	  </p>
	  </div>
	  <div style={{"paddingBottom": "63vh"}}></div>
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
